const env = {};
const PROD = true;
const URL = PROD ? "https://api.918kiss.team" : "http://localhost";
env.url = URL;
env.port = P_PORT;
env.apiUrl = `${URL}:${P_PORT}/api/v1`;
env.parseServerUrl = `${URL}:${P_PORT}/parse`;
env.parseServerUrl_2 = `${URL}:${P_SLAVE_PORT}/parse`;
env.lineURL = "#";
env.lineName = "@918kissteam";
env.appName = "918kiss team";
env.version = "4.0.0";
export default env;
